// extracted by mini-css-extract-plugin
export var mvpWebDevBenefitsSection = "z_fc";
export var mvpWebDevBusinessTypeSection = "z_d8";
export var mvpWebDevCaseSection = "z_fh";
export var mvpWebDevClientQuotes = "z_fj";
export var mvpWebDevExpertiseSection = "z_fg";
export var mvpWebDevIndustriesSection = "z_d7";
export var mvpWebDevPracticesSection = "z_fd";
export var mvpWebDevPrimeSection = "z_d5";
export var mvpWebDevProcessSection = "z_d9";
export var mvpWebDevProjLogoSection = "z_ff";
export var mvpWebDevServicesSection = "z_d6";
export var mvpWebDevTechStackSection = "z_fb";